import { render, staticRenderFns } from "./CarouselPagination.vue?vue&type=template&id=754772f8&scoped=true&"
import script from "./CarouselPagination.vue?vue&type=script&lang=js&"
export * from "./CarouselPagination.vue?vue&type=script&lang=js&"
import style0 from "./CarouselPagination.vue?vue&type=style&index=0&id=754772f8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "754772f8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/pbk/pim/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('754772f8')) {
      api.createRecord('754772f8', component.options)
    } else {
      api.reload('754772f8', component.options)
    }
    module.hot.accept("./CarouselPagination.vue?vue&type=template&id=754772f8&scoped=true&", function () {
      api.rerender('754772f8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/ui/src/components/Carousel/CarouselPagination.vue"
export default component.exports